import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import {ICategory} from '../../models/categories/categories.interface';
import {Injectable} from '@angular/core';

export interface CategoriesState extends EntityState<ICategory, number> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'categories' })
export class CategoriesStore extends EntityStore<CategoriesState> {
  constructor() {
    super() ;
  }
}
